<script>
export default {
  name: 'LoginFailed',
  mounted() {
    localStorage.removeItem('authDetails');
    localStorage.removeItem('vuex');
    setTimeout(() => {
      this.$gtag.event('Toggle', {
        event_category: 'User',
        event_label: 'LoginFailed',
      });

      this.$router.push({
        name: 'ClientPlatform',
      });
    }, 5000);
  },
};
</script>

<template>
  <div>
    <h1>Unable to log in</h1>
    <p>
      Should the issue persist please contact us at
      <strong>
        <a :href="`mailto:${$t('site.supportEmail')}}`">{{ $t('site.supportEmail') }}</a></strong
      >.
    </p>

    <p>Please wait to be automatically redirected, or <a href="#">click here</a> instead.</p>
  </div>
</template>
